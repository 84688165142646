import Link from "next/link";
import { paths } from "../routing/paths";

export default function UnauthorizedPage() {
  return (
    <div className="justify-center content-center flex flex-col h-screen text-center">
      <p className="font-extrabold text-primary-blue text-6xl">404</p>
      <br />
      <p className="font-normal text-lg">This page could not be found.</p>
      <Link href={paths.dashboard()}>
        <a className="text-secondary-lightBlue mt-4">
          Go back to the Home Page
        </a>
      </Link>
    </div>
  );
}
